<template>
    <columns>
        <column>
            <text-input 
                classes="is-rounded is-medium" 
                description="A descriptive label for this checkbox"
                :error="$root.errors['handler_payload.label']"
                required 
                v-model="handler.label">
                Label
            </text-input>
        </column>
        <column>
            <select-input 
                classes="is-rounded is-medium" 
                required 
                :items="options"
                no-revert
                description="Sets whether or not the checkpoint can fail"
                :error="$root.errors['handler_payload.fails_on']"
                v-model="handler.fails_on">
                Failure Setting
            </select-input>
        </column>
    </columns>
</template>
<script>
import { mapGetters } from 'vuex'
import { CheckboxCheckpoint } from '@/store/models/Checkpoint'

export default {

    data: () => ({

        handler: new CheckboxCheckpoint(),
        
        options: [
            { value: 'does_not_fail', label: 'Does Not Fail' },
            { value: 'checked', label: 'Fails if Checked' },
            { value: 'not_checked', label: 'Fails if Not Checked' },
        ]
    }),

    created() {
        if (this.checkpoint.id) {
            this.handler = new CheckboxCheckpoint(this.checkpoint.handler_payload)
        } else {
            setTimeout(() => {
                this.handler.fails_on = 'does_not_fail'
            }, 50)
        }
    },

    methods: {
        updateHandler() {
            this.$store.commit('checkpoint/setHandlerPayload', this.handler)
        }
    },

    computed: {
        ...mapGetters('checkpoint', [
            'checkpoint'
        ])
    },

    watch: {
        'handler.label': 'updateHandler',
        'handler.fails_on': 'updateHandler',
    }

}
</script>